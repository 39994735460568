<template>
  <layout class="relative">
    <template v-if="state.isLoading">
      <div class="absolute inset-0 flex items-center justify-center mt-16 md:mt-0">
        <icon-loading class="w-6 h-6 text-blumine mr-2" /> Loading...
      </div>
    </template>
    <template v-else-if="state.hasError">
      <alert-response-warning :error="state.accessError" />
    </template>
    <template v-else>
      <filter-header
        :active-machine="state.activeMachine"
        :machines="state.machines"
        :realtime="true"
        v-model:isRangeModalOpen="state.isRangeModalOpen"
      />

      <div class="flex flex-col space-y-4 md:space-y-6 lg:space-y-8">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 xl:gap-6 max-w-7xl">

          <!-- MACHINE INFO -->
          <div class="flex flex-col gap-y-2">
            <div>
              <h3 class="mb-1 font-semibold uppercase text-sm">Current Conditions</h3>
              <machine-current-conditions
                :weather="state.machineStatus.weather"
                :timezone="state.machineStatus.timezone"
                :errors="state.machineStatus.errors"
                :stats="state.data.vend_stats"
                @open-machine-detail="() => state.isMachineDetailOpen = true"
              />
            </div>
          </div>

          <!-- MACHINE Vends -->
          <div class="flex flex-col justify-between" v-show="state.machineStatus.weather">
            <div>
              <h3 class="mb-1 font-semibold uppercase text-sm">Ice Vends</h3>
              <machine-ice-vends :data="state.data.vend_stats" />
            </div>
            <div class="mt-2">
              <h3 class="mb-1 font-semibold uppercase text-sm">Water Vends</h3>
              <machine-water-vends :data="state.data.vend_stats" />
            </div>
          </div>
        </div>

        <chart-card :is-loading="state.isLoading" title="Events Last Hour">
          <events-last-hour-chart :data="state.data.last_hour" />
        </chart-card>

        <chart-card :is-loading="state.isLoading" title="Activity Per Hour (Last 24 hours)">
          <activity-per-hour-chart :data="state.data.last_day" />
        </chart-card>

        <chart-card :is-loading="state.isLoading" title="Hourly Report (Last 24 hours)">
          <hourly-report-table
            :data="state.data.last_day"
            use-timestamp
            include-local-time
            :timezone="state.machineStatus.timezone"
          />
        </chart-card>
      </div>
    </template>

    <slideover v-model:isOpen="state.isMachineDetailOpen" @close="() => state.isMachineDetailOpen = false">
      <machine-show v-if="state.activeMachine.location_id" :machine-id="state.activeMachine.location_id"  />
    </slideover>

    <date-range-modal
      v-if="state.isRangeModalOpen"
      v-model:isRangeModalOpen="state.isRangeModalOpen"
      :range="state.range"
      @on-submit="onDateRangeModalSubmit"
    />
  </layout>
</template>

<script>
import { onBeforeUnmount, reactive } from 'vue'
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'
import http from '@/services/http.js'
import date from '@/helpers/date'
import { store } from '@/services/store.js'
import Layout from '@/layouts/Default.vue'
import AlertResponseWarning from '@/components/alerts/ResponseWarning.vue'
import ActivityPerHourChart from '@/components/reports/ActivityPerHourChart.vue'
import EventsLastHourChart from '@/components/reports/EventsLastHourChart.vue'
import FilterHeader from '@/components/reports/FilterHeader.vue'
import HourlyReportTable from '@/components/reports/HourlyReportTable.vue'
import MachineCurrentConditions from '@/components/reports/MachineCurrentConditions.vue'
import MachineIceVends from '@/components/reports/MachineIceVends.vue'
import MachineWaterVends from '@/components/reports/MachineWaterVends.vue'
import ChartCard from '@/components/reports/ChartCard.vue'
import { IconLoading } from '@/components/icons'
import Slideover from '@/components/Slideover.vue'
import MachineShow from '@/views/machines/Show.vue'
import DateRangeModal from '@/components/modals/DateRange.vue'

export default {
  name: 'ReportsRealTime',

  components: {
    Layout,
    FilterHeader,
    ChartCard,
    ActivityPerHourChart,
    EventsLastHourChart,
    HourlyReportTable,
    MachineCurrentConditions,
    MachineIceVends,
    MachineWaterVends,
    AlertResponseWarning,
    IconLoading,
    Slideover,
    MachineShow,
    DateRangeModal,
  },

  setup() {
    const route = useRoute()
    const router = useRouter()
    const timeouts = []
    const state = reactive({
      machines: [],
      activeMachine: {},
      machineStatus: {},
      isLoading: true,
      hasError: false,
      accessError: null,
      isMachineDetailOpen: false,
      isRangeModalOpen: false,
      range: {
        from: date.now().subtract('7', 'days').format('YYYY-MM-DD'),
        to: date.now().format('YYYY-MM-DD'),
      },
    })

    const navigateToMachine = (id) => {
      if (route.name !== 'ReportsRealTime')  {
        return
      }
      router.push({ name: 'ReportsRealTime', params: { machineId: parseInt(id) } })
    }

    const init = async () => {
      state.hasError = false
      state.accessError = null

      try {
        const { data: machines } = await http.get('user/machines')

        state.machines = machines.allowed_locations.filter(m => !m.is_technician)

        if (route.params.machineId) {
          const { data: machine } = await http.get(`/machine/${parseInt(route.params.machineId)}`)
          if (machine) {
            state.activeMachine = machine.model
            store.selectedMachineId = machine.model.location_id
            await getData()
          }
          else {
            state.hasError = true
            state.accessError = 'You are not allowed to view this machine'
          }
        }
        else if (state.machines.length === 0) {
          state.hasError = true
          state.accessError = 'You either do not have any machines assigned or you are now allowed to view reports on any of the assigned machines.'
        }
        else {
          navigateToMachine(state.machines[0].location_id)
          return
        }
      } catch (e) {
        state.hasError = true
        state.accessError = e
      }

      state.isLoading = false
    }

    const getData = async () => {
      try {
        const [{ data }, { data: machineStatus }] = await Promise.all([
          http.get(`report/${state.activeMachine.location_id}/hourly`),
          http.get(`dashboard/${state.activeMachine.location_id}`)
        ])
        state.data = data
        state.machineStatus = machineStatus

        timeouts.push(
          setTimeout(() => getData(), 10000)
        )
      } catch (e) {
        //
      }

      state.isLoading = false
    }

    const onDateRangeModalSubmit = (range) => {
      state.isRangeModalOpen = false
      state.range = range
      goToHourlyReport()
    }

    const goToHourlyReport = () => {
      router.push({
        name: 'ReportsRealTimeHourly',
        params: { machineId: state.activeMachine.location_id },
        query: { from: state.range.from, to: state.range.to }
      })
    }

    init()

    onBeforeRouteUpdate(() => {
      setTimeout(() => {
        state.isLoading = true
        timeouts.forEach((timeout) => clearTimeout(timeout))
        init()
      }, 100)
    })

    onBeforeUnmount(() => {
      timeouts.forEach((timeout) => clearTimeout(timeout))
    })

    return {
      state,
      onDateRangeModalSubmit,
    }
  }
}
</script>
