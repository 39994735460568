<template>
  <div class="px-2 py-4 relative">
    <div v-if="state.graph.data.length === 0" class="flex justify-center items-center p-12">No Data</div>
    <chart v-else type="bar"
      :labels="state.graph.labels"
      :data="state.graph.data"
      :options="{
        scales: {
          xAxes: [{ stacked: false }],
          yAxes: [{
            stacked: false,
            ticks: {
              beginAtZero: true,
            },
          }]
        }
      }"
    />
  </div>
</template>

<script>
import { reactive, watch } from 'vue'
import Chart from '@/components/charts/Chart.vue'
import { sortAlpha } from '@/helpers/utils.js'

export default {
  name: 'ReportsEventsHourChart',

  components: {
    Chart,
  },

  props: ['data'],

  setup(props) {
    const state = reactive({
      graph: {
        data: [],
        labels: [],
      },
    })

    const extractKeys = (data) => {
      const keys = []

      data.forEach((minute) => minute.items.forEach((item) => ! keys.includes(item.label) ? keys.push(item.label) : null))

      return keys
    }

    const generateChartData = (data) => {
      state.graph.data = []
      const keys = sortAlpha(extractKeys(data))
      const range = [0, -5, -10, -15, -20, -25, -30, -35, -40, -45, -50, -55, -60].sort((a, b) => a - b)

      keys.forEach((key) => {
        const values = []

        range.forEach((minutes) => {
          const minutesData = data.find((i) => i.minutes === minutes)

          if (! minutesData) {
            values.push(0)

            return
          }

          const item = minutesData.items.find((i) => i.label === key)
          values.push(item ? item.amount : 0)
        })

        state.graph.data.push({
          label: key,
          data: values
        })
      })

      state.graph.labels = range
    }

    watch(
      () => props.data,
      () => {
        generateChartData(props.data)
      }
    )

    generateChartData(props.data)

    return {
      state,
    }
  }
}
</script>
