<template>
  <div class="flex flex-col items-center rounded-md shadow-sm bg-white text-big-stone border-t-8 border-big-stone">
    <!-- Status -->
    <button @click="$emit('open-machine-detail')" type="button" class="focus:outline-none">
      <div class="flex flex-row space-x-4 p-4 items-center justify-center h-24">
        <div v-for="status in statusIcons" :key="status.icon">
          <component :is="errorIconLookup(status.icon)" class="h-8 w-8 lg:h-10 lg:w-10 mx-1 text-red-500" />
        </div>
        <icon-status-ok v-if="statusIcons.length === 0" class="h-8 w-8 lg:h-10 lg:w-10 mx-1" />
      </div>
    </button>
    <!-- Weather & Time -->
    <div class="flex flex-row items-center w-full border-t divide-x border-gray-200 divide-gray-200">
      <div class="w-1/2">
        <div v-if="weather" :title="weather.icon.replace('-', ' ')" class="flex justify-center items-center p-4">
          <div class="flex flex-row items-center space-x-2 flex-wrap">
            <component :is="weatherIconLookup[weather.icon]" class="h-16 w-16 lg:h-20 lg:w-20" />
            <div class="font-bold text-3xl lg:text-4xl xxl:text-5xl leading-none">{{ Math.round(weather.temp) }} °F</div>
          </div>
        </div>
        <div v-else class="flex py-4 px-8 font-bold text-xl lg:text-2xl xxl:text-3xl leading-none justify-center">n/a</div>
      </div>
      <div class="w-1/2">
        <div class="flex flex-col items-center justify-center p-4">
          <div class="font-bold text-2xl lg:text-3xl xxl:text-4xl leading-none lg:leading-none">
            {{ time }}
          </div>
          <div class="text-sm font-semibold">{{ date }}</div>
        </div>
      </div>
    </div>
    <!-- Efficiency -->
    <div class="flex flex-row w-full border-t divide-x items-center">
      <div class="w-1/3 flex flex-col items-center justify-center space-y-1 h-full p-4">
        <div class="font-bold text-3xl lg:text-4xl leading-none lg:leading-none">
          {{ harvestVendsPercentage }}%
        </div>
        <div class="text-sm font-semibold">Harvets/Vends</div>
      </div>
      <div class="w-2/3 px-4 lg:text-lg text-gray-500 flex flex-row justify-start py-6 space-x-2">
        <div>Harvests: <span class="font-semibold text-gray-700">{{ stats.harvests }}</span></div>
        <div>Vends: <span class="font-semibold text-gray-700">{{ stats.ice_vends }}</span></div>
      </div>
    </div>
    <!-- Revenue -->
    <div class="flex flex-row w-full border-t divide-x items-center">
      <div class="w-1/3 flex flex-col items-center justify-center space-y-1 h-full p-4">
        <div class="font-bold text-3xl lg:text-4xl leading-none lg:leading-none">
          ${{ (stats.ice_revenue + stats.water_revenue) / 100 }}
        </div>
        <div class="text-sm font-semibold">Total Revenue</div>
      </div>
      <div class="w-2/3 px-4 lg:text-lg text-gray-500 flex flex-row justify-start py-6 space-x-2">
        <div>I. Revenue: <span class="font-semibold text-gray-700">${{ stats.ice_revenue / 100 }}</span></div>
        <div>W. Revenue: <span class="font-semibold text-gray-700">${{ stats.water_revenue / 100 }}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import { IconStatusOk } from '@/components/icons'
import { errorIconLookup, convertErrorsToArray } from '@/helpers/machine.js'
import { computed } from '@vue/runtime-core'
import {
  IconClearDay,
  IconClearNight,
  IconCloudy,
  IconFog,
  IconPartlyCloudyDay,
  IconPartlyCloudyNight,
  IconSnow,
  IconRain,
  IconWind,
} from '@/components/icons/weather'
import dateLib from '@/helpers/date.js'
import { ref } from '@vue/reactivity'
import { onBeforeUnmount } from '@vue/runtime-core'

export default {
  name: 'ReportsCurrentConditions',

  components: {
    IconStatusOk
  },

  emits: ['open-machine-detail'],

  props: ['errors', 'weather', 'timezone', 'stats'],

  setup(props) {
    const statusIcons = computed(() => {
      return convertErrorsToArray(props.errors)
    })
    const harvestVendsPercentage = computed(() => {
      if (! props.stats.ice_vends) {
        return 0
      }

      return Math.round(props.stats.harvests / props.stats.ice_vends * 100)
    })

    const time = ref('')
    const date = ref('')

    const updateCurrentTime = () => {
      const current = dateLib.now().tz(props.timezone)
      time.value = current.format('hh:mm:ss a')
      date.value = current.format('dddd MMM DD, YYYY')
    }

    const intervalHandle = setInterval(() => {
      updateCurrentTime()
    }, 1000)

    onBeforeUnmount(() => {
      clearInterval(intervalHandle)
    })

    updateCurrentTime()

    return {
      time,
      date,
      statusIcons,
      errorIconLookup,
      harvestVendsPercentage,
      weatherIconLookup: {
        'clear-day': IconClearDay,
        'clear-night': IconClearNight,
        cloudy: IconCloudy,
        fog: IconFog,
        'partly-cloudy-day': IconPartlyCloudyDay,
        'partly-cloudy-night': IconPartlyCloudyNight,
        snow: IconSnow,
        rain: IconRain,
        wind: IconWind,
      }
    }
  },
}
</script>
